import { create } from '@storybook/theming/create'
import { addons } from '@storybook/manager-api'

import Logo from './logo.svg'

const darkSkyColor = '#001F4A'
const whiteColor = '#ffffff'

const infomediaTheme = create({
  base: 'light',
  brandTitle: 'Infomedia Storybook',
  brandImage: Logo,
  brandTarget: '_self',

  colorSecondary: darkSkyColor,

  textColor: darkSkyColor,
  textInverseColor: whiteColor,

  barSelectedColor: darkSkyColor,
  barBg: whiteColor,
})

addons.setConfig({
  theme: infomediaTheme,
})
